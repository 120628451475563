@tailwind components;

@layer components {
  .bg-dialog-overlay {
    @apply bg-blue-900/20;
  }

  .bg-dialog {
    @apply bg-white;
  }

  b {
    @apply font-semibold;
  }

  .quill {
    @apply font-sans;
    @apply transition-all duration-75 ease-in-out;
    @apply rounded-md outline-2;
    @apply border border-slate-200;
    @apply focus-within:border-transparent;
    @apply focus-within:outline focus-within:outline-blue-500;
  }
  .quill .ql-container {
    @apply !border-none;
    @apply prose prose-sm prose-slate;
    @apply pointer-events-auto;
  }
  .quill .ql-container .ql-editor {
    @apply max-h-80 pt-0 pb-1 text-base;
    @apply overflow-auto;
  }
  .quill .ql-container .ql-editor.ql-placeholder-active {
    @apply absolute inset-0;
  }
  .quill .ql-toolbar {
    @apply !border-none;
    @apply text-slate-500;
  }
  .quill .ql-picker {
    @apply z-0;
    @apply rounded-sm;
    @apply text-slate-500;
  }

  .quill .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    @apply !border-transparent;
  }
  .quill .ql-picker.ql-expanded {
    @apply !z-10;
  }
  .quill .ql-toolbar *:hover {
    @apply text-slate-800;
  }

  .quill .ql-stroke {
    @apply stroke-slate-500;
  }

  .quill .ql-toolbar button {
    @apply hover:!text-primary;
  }

  .quill .ql-toolbar button.ql-active {
    @apply !text-primary;
  }

  .quill .ql-toolbar button:hover .ql-stroke {
    @apply !stroke-slate-800;
  }

  .quill .ql-toolbar button:hover .ql-fill {
    @apply !fill-slate-800;
  }

  .quill .ql-toolbar button.ql-active .ql-stroke {
    @apply !stroke-primary;
  }

  .quill .ql-fill {
    @apply fill-slate-500;
  }

  .quill .ql-picker .ql-picker-options {
    @apply shadow-card-1 z-10 rounded border-none;
  }

  .quill .ql-placeholder {
    @apply pointer-events-none sticky top-0 mt-0 p-4 italic text-slate-400;
  }

  .quill .ql-placeholder > *:first {
    @apply !mt-0 !pt-0;
  }

  .quill .ql-placeholder.ql-hidden {
    @apply hidden;
  }

  .prose li:has(> ul),
  .prose li:has(> ol) {
    list-style: none;
  }
  .no-select {
    -webkit-tap-highlight-color: transparent; /* Removes touch highlight on iOS */
    -webkit-touch-callout: none; /* Prevents callout menu on iOS */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Standard syntax */
    outline: none; /* Removes focus outline */
  }  
}
